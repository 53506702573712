import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType, } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import { ShowLoading} from "../../../core/app.store";

const service = new Service();

const LOADLISTNEWS = "NEWS/LOADLISTNEWS";
const SAVEISDELETE = "NEWS/SAVEISDELETE";
const SAVETOTALITEMCOUNT = "NEWS/SAVETOTALITEMCOUNT";
const SAVEISHOT = "NEWS/SAVEISHOT";
const SAVESTATUS = "NEWS/SAVESTATUS";
const SAVEPAGEINDEX = "NEWS/SAVEPAGEINDEX";
const SAVEPAGESIZE = "NEWS/SAVEPAGESIZE";
const SAVESORTEXPRESSION = "NEWS/SAVESORTEXPRESSION";
const SAVEDETAILNEWS = "NEWS/SAVEDETAILNEWS";

const saveListNews = (data) => ({
    type: LOADLISTNEWS,
    data: data,
})
const saveDetailNews = (data) => ({
    type: SAVEDETAILNEWS,
    data: data,
})
const saveIsDelete = (data) => ({
    type: SAVEISDELETE,
    data: data,
})
const saveTotalItemCount = (data) => ({
    type: SAVETOTALITEMCOUNT,
    data: data,
})
const saveIsHotStatus = (data) => ({
    type: SAVEISHOT,
    data: data,
})
const saveStatus = (data) => ({
    type: SAVESTATUS,
    data: data,
})
const savePageIndex = (data) => ({
    type: SAVEPAGEINDEX,
    data: data,
})
const savePageSize = (data) => ({
    type: SAVEPAGESIZE,
    data: data,
})
const saveSortExpression = (data) => (
    {
        type: SAVESORTEXPRESSION,
        data: data,
    })

const InitState = {
    categoryId:1,
    newsId: 0,
    newsDetail: undefined,
    newsModels: [],
    isDelete: 0,
    isHot: undefined,
    isFeature:undefined,
    status: undefined,
    pageIndex: 1,
    pageSize: config.Configs.DefaultPageSize,
    sortExpression: "modifiedDate desc",
    totalItemCount: 0,
}

export default function NewsReducer(state = InitState, action)
{
    switch (action.type) {
        case LOADLISTNEWS:
            return { ...state, newsModels: action.data };
        case SAVEDETAILNEWS:
            return { ...state, newsDetail: action.data };
        case SAVEISDELETE:
            return { ...state, isDelete: action.data };
        case SAVETOTALITEMCOUNT:
            return { ...state, totalItemCount: action.data };
        case SAVEISHOT:
            return { ...state, isHot: action.data };
        case SAVESTATUS:
            return { ...state, status: action.data };
        case SAVEPAGEINDEX:
            return { ...state, pageIndex: action.data };
        case SAVEPAGESIZE:
            return { ...state, pageSize: action.data };
        case SAVESORTEXPRESSION:
            return { ...state, sortExpression: action.data };
        default:
            return state;
    }
}

export const Search = (searchData) =>
    (dispatch) => {
    dispatch(GetList(undefined, searchData.title, undefined,undefined, undefined,searchData.status, undefined, undefined, undefined));
}
export const GetList = (categoryId, title = "", isHot , isFeature , isDelete , status ,
    pageIndex, pageSize, sortExpression) =>
    (dispatch, getState) => {
        dispatch(ShowLoading(true));
        const params = new URLSearchParams();
        params.append('categoryId', categoryId ? categoryId : getState().initNews.categoryId);
        params.append('isHot', isHot !== undefined ? isHot : getState().initNews.isHot);
        params.append('isFeature', isFeature !== undefined ? isFeature : getState().initNews.isFeature);
        params.append('isDelete', isDelete !== undefined ? isDelete : getState().initNews.isDelete);
        params.append('status', status !== undefined ? status : getState().initNews.status);
        params.append('pageIndex', pageIndex != undefined ? pageIndex : getState().initNews.pageIndex);
        params.append('pageSize', pageSize != undefined ? pageSize : getState().initNews.pageSize);
        params.append('sorting', sortExpression !== undefined ? sortExpression : getState().initNews.sortExpression);
        title && params.append('title', title);
        service.get(ApiUrl.News_GetListAll, params).then(res => {
            dispatch(ShowLoading(false));
            dispatch(
                saveListNews(res.content?.items)
            );
            dispatch(
                saveTotalItemCount(res.content.totalItemCount)
            );
        }).catch(err => {
            dispatch(ShowLoading(false));
            throw err
        });
    }

export const GetDetail = (id) =>
    (dispatch) => {
        dispatch(ShowLoading(true));
        const params = new URLSearchParams();
        params.append("id", id);
        service.get(ApiUrl.News_GetDetail.replace("{id}", id)).then(res => {
            dispatch(ShowLoading(false));
            dispatch(
                saveDetailNews(res.content)
            );
        }).catch(err => {
            dispatch(ShowLoading(false));
            ShowNotification(
                viVN.Errors[(err && err.errorType) || "UnableHandleException"],
                NotificationMessageType.Error
            );
        });
    }

export const ClearDetail = () => (dispatch) => {
    dispatch(saveDetailNews(undefined));
}

export const Create = (body) => {
    return (dispatch) => {
        return service.post(ApiUrl.News_Create, body).then(res => {
            ShowNotification(
                viVN.Success.NewsAddSuccess,
                NotificationMessageType.Success
            );
            dispatch(GetList());
            return true;
        }).catch(err => {
            ShowNotification(
                viVN.Errors[(err && err.errorType) || "UnableHandleException"],
                NotificationMessageType.Error
            );
            return false;
        });
    }
}

export const Update = (body) => {
    return (dispatch) => {
        return service.post(ApiUrl.News_Update, body).then(res => {
            ShowNotification(
                viVN.Success.NewsEditSuccess,
                NotificationMessageType.Success
            );
            dispatch(GetList());
            return true;
        }).catch(err => {
            ShowNotification(
                viVN.Errors[(err && err.errorType) || "UnableHandleException"],
                NotificationMessageType.Error
            );
            return false;
        });
    }
}

export const Delete = (id, xmin) => {
    return (dispatch) => {
        return service.postParams(ApiUrl.News_Delete + "/" + id + "/" + xmin).then(res => {
            ShowNotification(
                viVN.Success.NewsDeleteSuccess,
                NotificationMessageType.Success
            );
            dispatch(GetList());
            return true;
        }).catch(err => {
            ShowNotification(
                viVN.Errors[(err && err.errorType) || "UnableHandleException"],
                NotificationMessageType.Error
            );
            return false;
        });
    }
}

export const TrashEmpty = () => (dispatch)=>{
    service.post(ApiUrl.News_EmptyTrash).then(res => {
        ShowNotification(
            viVN.Success.NewsDeleteSuccess,
            NotificationMessageType.Success
        );
        dispatch(GetList());
    }).catch(err => {
        ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
        );
    });
}

export const Restore = (id) => (dispatch)=>{
    service.postParams(ApiUrl.News_Restore.replace("{id}", id)).then(res => {
        ShowNotification(
            viVN.Success.NewsRestoreSuccess,
            NotificationMessageType.Success
        );
        dispatch(GetList());
    }).catch(err => {
        ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
        );
    });
}

export const ChangeShowTrash = (data) => (dispatch) => {
    console.log(data);
    dispatch(saveIsDelete(data));
    dispatch(GetList());
}

export const ChangeHotStatus = (data) => (dispatch) => {
    dispatch(saveIsHotStatus(data));
}

export const ChangeStatus = (data) => (dispatch) => {
    dispatch(saveStatus(data));
}

export const ChangePageIndex = (data) => (dispatch) => {
    dispatch(savePageIndex(data));
    dispatch(GetList());
}

export const ChangePageSize = (data) => (dispatch) => {
    dispatch(savePageSize(data));
    dispatch(savePageIndex(1));
    dispatch(GetList());
}

export const ChangeSort = (data) => (dispatch) => {
    dispatch(saveSortExpression(data));
    dispatch(GetList());
}